/**
 * 使用情况分析接口文件
 */
import axios from "@/plugins/axios.js";
/**
 * 使用率分析接口
 * @return {Promise}
 */
export function getRate(data) {
  return axios.get("/bi/dailyUse/rate", {
    params: data,
  });
}
/**
 * 人均使用时长分析接口
 * @return {Promise}
 */
export function getAvgTime(data) {
  return axios.get("/bi/dailyUse/avgTime", {
    params: data,
  });
}
/**
 * 未使用的老师接口
 * @return {Promise}
 */
export function noUseTeacher(data) {
  return axios.get("/bi/dailyUse/noUseTeacher", {
    params: data,
  });
}

/**
 * 未使用系统的老师接口
 * @return {Promise}
 */
export function noUseSystemTeacher(data) {
  return axios.get("/bi/detailUse/noUsePlatform", {
    params: data,
  });
}
/**
 * 已开通教师账号数
 * @return {Promise}
 */
export function countTeacher(schoolCode) {
  return axios.get("/bi/detailUse/teacher/count", {
    params: {
      schoolCode,
    },
  });
}
