<template>
  <div class="tab_manage">
    <div class="tab_manage_box">
      <ul class="tab_manage_menu">
        <!-- 课程推荐 -->
        <li
          v-if="checkAuthMenu('/manage/courseRecommendations')"
          @click="changeTopItem(topTab.COURSE_RECOMMENDATIONS)"
          :class="{
            active: currentTopTab === topTab.COURSE_RECOMMENDATIONS,
          }"
        >
          {{ $t("el.common.CourseRecommendations") }}
        </li>
        <!-- 排课管理 -->
        <li
          v-if="showTopItem($D('PLAN_COURSE', 'leftTabList'))"
          @click="changeTopItem(topTab.PLAN_COURSE)"
          :class="{
            active: currentTopTab === topTab.PLAN_COURSE,
          }"
        >
          {{ $t("el.common.coursesArrangingManagement") }}
        </li>
        <!-- 基础设置 -->
        <li
          v-if="showTopItem($D('BASIC_SETTING', 'leftTabList'))"
          @click="changeTopItem(topTab.BASIC_SETTING)"
          :class="{
            active: currentTopTab === topTab.BASIC_SETTING,
          }"
        >
          {{ $t("el.common.infrastructure") }}
        </li>
        <!-- 学校资源管理 -->
        <li
          v-if="showTopItem($D('SCHOOL_RESOUSE', 'leftTabList'))"
          @click="changeTopItem(topTab.SCHOOL_RESOUSE)"
          :class="{
            active: currentTopTab === topTab.SCHOOL_RESOUSE,
          }"
        >
          {{ $t("el.common.schoolResourcesManagement") }}
        </li>
        <!-- 数据分析 -->
        <li
          v-if="showTopItem($D('DATA_ANALYSIS', 'leftTabList'))"
          @click="changeTopItem(topTab.DATA_ANALYSIS)"
          :class="{
            active: currentTopTab === topTab.DATA_ANALYSIS,
          }"
        >
          {{ $t("el.common.dataAnalysis") }}
        </li>
        <!-- 订单管理 -->
        <li
          v-if="showTopItem($D('ORDER', 'leftTabList'))"
          @click="changeTopItem(topTab.ORDER)"
          :class="{
            active: currentTopTab === topTab.ORDER,
          }"
        >
          {{ $t("el.common.orderManagement") }}
        </li>
      </ul>
      <!-- <div
        class="tab_manage_num"
        v-if="$route.name !== 'teachManage' || $route.name === 'authorize'"
      >
        * &nbsp;已开通教师账号数：{{ countTeacher }}
      </div> -->
    </div>
    <div class="flex bottom-content">
      <el-tabs
        @tab-click="changeItem"
        class="tab_manage_menu"
        tab-position="left"
        v-model="activeLeftName"
        v-if="leftTabListDiaplay.length > 0"
      >
        <el-tab-pane
          v-for="item in leftTabListDiaplay"
          :key="item.name"
          :name="item.name"
          :label="$t(item.label)"
        ></el-tab-pane>
      </el-tabs>
      <div
        class="right-content"
        :style="
          activeLeftName === 'courseRecommendations' ? 'width: 100%;' : ''
        "
      >
        <keep-alive>
          <router-view
            v-if="$route.meta.keepAlive"
            @changeSchool="onChangeSchool"
          />
        </keep-alive>
        <router-view
          v-if="!$route.meta.keepAlive"
          @changeSchool="onChangeSchool"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { windowOpen } from "@/utils";
import { mapGetters, mapState } from "vuex";
import { countTeacher } from "@/api/manage/usage-analyze";
import { getPathByName } from "@/router";
import { checkAuthMenu } from "@/utils/auth";

export default {
  name: "tabManage",
  data() {
    return {
      topTab: {
        COURSE_RECOMMENDATIONS: "COURSE_RECOMMENDATIONS",
        PLAN_COURSE: "PLAN_COURSE",
        BASIC_SETTING: "BASIC_SETTING",
        SCHOOL_RESOUSE: "SCHOOL_RESOUSE",
        DATA_ANALYSIS: "DATA_ANALYSIS",
        ORDER: "ORDER",
      },
      countTeacher: 0,
      leftTabList: [],
      currentTopTab: "",
      activeLeftName: "",
      queryDictionaryKey: "",
    };
  },
  created() {
    this.init();
    // this.getCountTeacher("");
  },
  // 计算属性
  computed: {
    ...mapGetters(["eduAuth", "reviewAuth", "canAccess"]),
    ...mapState(["dict"]),

    // 根据权限显示侧边栏
    leftTabListDiaplay() {
      const display = this.leftTabList.filter((item) => {
        return checkAuthMenu(getPathByName(item.name));
      });
      console.log(display);
      return display;
    },
  },
  watch: {
    // 判断一级以及二级菜单 active状态
    $route(val) {
      this.setActiveTab(val);
    },
    activeLeftName(val, oldVal) {
      // 数据分析tab，回到上个选项
      if (val === "statisticalBoard") {
        if (oldVal) {
          this.$nextTick(() => {
            this.activeLeftName = oldVal;
          });
        }
      }
    },
    // "$i18n.locale": {
    //   // 语言修改后，tab重新修改
    //   immediate: true,
    //   handler(val, old) {
    //     this.leftTabList = this.$D(this.queryDictionaryKey, "leftTabList");
    //   },
    // },
  },
  methods: {
    checkAuthMenu: checkAuthMenu,
    init() {
      this.setActiveTab(this.$route);
    },
    // 设置一级菜单以及二级菜单的active
    setActiveTab(route) {
      // 一级菜单active
      let FIRST_ACTIVE_TAB;
      this.dict.leftTabList.forEach((element) => {
        if (Array.isArray(element.label) && element.label.length > 0) {
          element.label.forEach((item) => {
            if (item.name === route.name) {
              FIRST_ACTIVE_TAB = element.key;
              // 二级菜单active
              this.activeLeftName = item.name;
              return;
            }
            if (item.children && item.children.length > 0) {
              item.children.forEach((ele) => {
                if (ele.name === route.name) {
                  // 二级菜单active
                  this.activeLeftName = item.name;
                  FIRST_ACTIVE_TAB = element.key;
                  return;
                }
              });
            }
          });
        } else {
          FIRST_ACTIVE_TAB = element.key;

          this.activeLeftName = element.name;
        }
      });
      this.leftTabList = this.$D(`${FIRST_ACTIVE_TAB}`, "leftTabList");
      this.currentTopTab = this.topTab[FIRST_ACTIVE_TAB];
      this.queryDictionaryKey = FIRST_ACTIVE_TAB;
    },
    changeTopItem(type) {
      if (type !== this.currentTopTab) {
        this.currentTopTab = type;
        switch (type) {
          case this.topTab.PLAN_COURSE: // 排课管理
            // 点击排课管理时，在 dict.js 配置排课管理菜单字典，切换左侧菜单栏和路由，修改上面 topTab 的 tab class 选中条件，case 下同。
            this.queryDictionaryKey = "PLAN_COURSE";
            this.leftTabList = this.$D("PLAN_COURSE", "leftTabList");
            this.leftTabList = this.getLeftTabListWidthAuth(this.leftTabList);
            this.activeLeftName = this.leftTabList[0].name;
            this.changeItem(this.leftTabList[0]);
            break;
          case this.topTab.BASIC_SETTING: // 基础设置
            this.queryDictionaryKey = "BASIC_SETTING";
            this.leftTabList = this.$D("BASIC_SETTING", "leftTabList");
            this.leftTabList = this.getLeftTabListWidthAuth(this.leftTabList);
            // 点击基础设置时，默认选中第一个
            this.activeLeftName = this.leftTabList[0].name;
            this.changeItem(this.leftTabList[0]);
            break;
          case this.topTab.SCHOOL_RESOUSE: // 学校资源管理
            this.queryDictionaryKey = "SCHOOL_RESOUSE";
            this.leftTabList = this.$D("SCHOOL_RESOUSE", "leftTabList");
            this.leftTabList = this.getLeftTabListWidthAuth(this.leftTabList);
            // 点击基础设置时，默认选中第一个
            this.activeLeftName = this.leftTabList[0].name;
            this.changeItem(this.leftTabList[0]);
            break;
          case this.topTab.DATA_ANALYSIS: // 数据分析
            this.queryDictionaryKey = "DATA_ANALYSIS";
            this.leftTabList = this.$D("DATA_ANALYSIS", "leftTabList");
            this.leftTabList = this.getLeftTabListWidthAuth(this.leftTabList);
            this.activeLeftName = this.leftTabList[0].name;
            this.changeItem(this.leftTabList[0]);
            break;
          case this.topTab.ORDER: // 订单管理
            this.queryDictionaryKey = "ORDER";
            this.leftTabList = this.$D("ORDER", "leftTabList");
            this.leftTabList = this.getLeftTabListWidthAuth(this.leftTabList);
            // 点击基础设置时，默认选中第一个
            this.activeLeftName = this.leftTabList[0].name;
            this.changeItem(this.leftTabList[0]);
            break;
          case this.topTab.COURSE_RECOMMENDATIONS: // 课程推荐
            this.queryDictionaryKey = "COURSE_RECOMMENDATIONS";
            this.leftTabList = [];
            this.activeLeftName = "courseRecommendations";
            this.changeItem({
              name: "courseRecommendations",
            });
            break;
          default:
            break;
        }
      }
    },
    changeItem(tab) {
      let pathname = tab.name;
      if (pathname !== "statisticalBoard") {
        this.$route.name !== pathname &&
          this.$router.push({
            name: pathname,
          });
      } else {
        if (this.canAccess) {
          let routeData = this.$router.resolve({
            name: pathname,
            query: {
              staticType: 3,
            },
          });
          windowOpen(routeData.href, "_blank");
        } else {
          this.$message.error("该用户暂无有效的学校数据");
        }
      }
    },
    getCountTeacher(schoolCode) {
      countTeacher(schoolCode).then((res) => {
        this.countTeacher = res.data;
      });
    },
    onChangeSchool() {
      let schoolCode = this.$store.state.schoolCode;
      this.getCountTeacher(schoolCode);
    },
    // 当前顶部分类 是否展示，判断侧边tab是否有权限
    showTopItem(leftTabList) {
      return leftTabList.some((item) => {
        return checkAuthMenu(getPathByName(item.name));
      });
    },
    // 获取左侧有权限的列表
    getLeftTabListWidthAuth(leftTabList) {
      const display = leftTabList.filter((item) => {
        return checkAuthMenu(getPathByName(item.name));
      });
      return display;
    },
  },
};
</script>

<style lang="less" scoped>
.tab_manage {
  &_box {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  &_menu {
    display: flex;
    padding: 0;
    margin-top: 31px;
    margin-bottom: 24px;
    & li {
      font-size: 15px;
      line-height: 20px;
      padding: 7px 24px;
      border-radius: 17px;
      color: #282828;
      cursor: pointer;
      // margin-right: 20px;
      // &:hover {
      //   background-color: #6f8aa3;
      //   color: #ffffff;
      // }
      &.active {
        background-color: #6049ff;
        color: #ffffff;
        box-shadow: 0px 2px 8px 0px rgba(111, 138, 163, 0.2);
      }
    }
  }
  /deep/ .el-tabs__nav-scroll {
    width: fit-content;
    min-width: 124px;
  }
  &_num {
    font-size: 14px;
    color: #646c95;
    line-height: 19px;
  }
  .bottom-content {
    .right-content {
      width: calc(100% - 154px);
      // max-height: 600px;
      overflow: auto;
    }

    /deep/ .tab_manage_menu {
      display: -webkit-box;
      display: -ms-flexbox;
      height: 600px;
      display: flex;
      padding: 0;
      margin-top: 0px;
      margin-right: 30px;
      background: #ffffff;
      margin-bottom: 24px;
    }
    /deep/ .el-tabs--left {
      overflow: visible;
    }
    /deep/ .el-tabs--left .el-tabs__item.is-left {
      text-align: left;
    }
    /deep/ .el-tabs__item.is-active {
      color: #6049ff;
      background: #f4f3ff;
      // width: 100%;
    }
    /deep/ .el-tabs__item:hover {
      color: #6049ff;
    }
    /deep/ .el-tabs__active-bar {
      // display: none;
      position: absolute;
      bottom: 0;
      left: auto;
      height: 2 px;
      background-color: #ffffff;
      z-index: 1;
      -webkit-transition: -webkit-transform 0.3s
        cubic-bezier(0.645, 0.045, 0.355, 1);
      transition: -webkit-transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
      transition: transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
      transition: transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1),
        -webkit-transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
      list-style: none;
    }
    /deep/ .el-tabs--left .el-tabs__nav-wrap.is-left:after,
    .el-tabs--left .el-tabs__nav-wrap.is-right:after,
    .el-tabs--right .el-tabs__nav-wrap.is-left:after,
    .el-tabs--right .el-tabs__nav-wrap.is-right:after {
      height: 0;
      width: 2 px;
      bottom: auto;
      top: 0;
    }
    /deep/ .el-tabs--left .el-tabs__header.is-left {
      float: left;
      margin-bottom: 0;
      margin-right: 0;
    }
  }
}
</style>
