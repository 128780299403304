var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"tab_manage"},[_c('div',{staticClass:"tab_manage_box"},[_c('ul',{staticClass:"tab_manage_menu"},[(_vm.checkAuthMenu('/manage/courseRecommendations'))?_c('li',{class:{
          active: _vm.currentTopTab === _vm.topTab.COURSE_RECOMMENDATIONS,
        },on:{"click":function($event){return _vm.changeTopItem(_vm.topTab.COURSE_RECOMMENDATIONS)}}},[_vm._v(" "+_vm._s(_vm.$t("el.common.CourseRecommendations"))+" ")]):_vm._e(),(_vm.showTopItem(_vm.$D('PLAN_COURSE', 'leftTabList')))?_c('li',{class:{
          active: _vm.currentTopTab === _vm.topTab.PLAN_COURSE,
        },on:{"click":function($event){return _vm.changeTopItem(_vm.topTab.PLAN_COURSE)}}},[_vm._v(" "+_vm._s(_vm.$t("el.common.coursesArrangingManagement"))+" ")]):_vm._e(),(_vm.showTopItem(_vm.$D('BASIC_SETTING', 'leftTabList')))?_c('li',{class:{
          active: _vm.currentTopTab === _vm.topTab.BASIC_SETTING,
        },on:{"click":function($event){return _vm.changeTopItem(_vm.topTab.BASIC_SETTING)}}},[_vm._v(" "+_vm._s(_vm.$t("el.common.infrastructure"))+" ")]):_vm._e(),(_vm.showTopItem(_vm.$D('SCHOOL_RESOUSE', 'leftTabList')))?_c('li',{class:{
          active: _vm.currentTopTab === _vm.topTab.SCHOOL_RESOUSE,
        },on:{"click":function($event){return _vm.changeTopItem(_vm.topTab.SCHOOL_RESOUSE)}}},[_vm._v(" "+_vm._s(_vm.$t("el.common.schoolResourcesManagement"))+" ")]):_vm._e(),(_vm.showTopItem(_vm.$D('DATA_ANALYSIS', 'leftTabList')))?_c('li',{class:{
          active: _vm.currentTopTab === _vm.topTab.DATA_ANALYSIS,
        },on:{"click":function($event){return _vm.changeTopItem(_vm.topTab.DATA_ANALYSIS)}}},[_vm._v(" "+_vm._s(_vm.$t("el.common.dataAnalysis"))+" ")]):_vm._e(),(_vm.showTopItem(_vm.$D('ORDER', 'leftTabList')))?_c('li',{class:{
          active: _vm.currentTopTab === _vm.topTab.ORDER,
        },on:{"click":function($event){return _vm.changeTopItem(_vm.topTab.ORDER)}}},[_vm._v(" "+_vm._s(_vm.$t("el.common.orderManagement"))+" ")]):_vm._e()])]),_c('div',{staticClass:"flex bottom-content"},[(_vm.leftTabListDiaplay.length > 0)?_c('el-tabs',{staticClass:"tab_manage_menu",attrs:{"tab-position":"left"},on:{"tab-click":_vm.changeItem},model:{value:(_vm.activeLeftName),callback:function ($$v) {_vm.activeLeftName=$$v},expression:"activeLeftName"}},_vm._l((_vm.leftTabListDiaplay),function(item){return _c('el-tab-pane',{key:item.name,attrs:{"name":item.name,"label":_vm.$t(item.label)}})}),1):_vm._e(),_c('div',{staticClass:"right-content",style:(_vm.activeLeftName === 'courseRecommendations' ? 'width: 100%;' : '')},[_c('keep-alive',[(_vm.$route.meta.keepAlive)?_c('router-view',{on:{"changeSchool":_vm.onChangeSchool}}):_vm._e()],1),(!_vm.$route.meta.keepAlive)?_c('router-view',{on:{"changeSchool":_vm.onChangeSchool}}):_vm._e()],1)],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }